import dynamic from "next/dynamic";
import { Section } from "../index";

const BasicSlide = dynamic(() =>
  import("../../elements/basic-slide/basic-slide")
);

const MAP_TYPE_COMPONENT = {
  ComponentSlidesBasic: BasicSlide,
};

export const Slider = ({ slider, displaySetting, newVersion = false }) => {
  const { sectionTitle, sectionSubtitle, hideLineSeparation, slides } = slider;

  return (
    <Section
      title={sectionTitle}
      subtitle={sectionSubtitle}
      id="slider-above"
      hideLine={hideLineSeparation}
      displayMode={displaySetting?.displayMode}
      smallMargin={displaySetting?.smallBottomMargin}
    >
      {slides.map((slide) => {
        const SlideComponent = MAP_TYPE_COMPONENT[slide.__typename];
        return <SlideComponent key={slide.__typename + slide.id} {...slide} newVersion={newVersion} />;
      })}
    </Section>
  );
};

export default Slider;
